import { useState } from 'react';
import './App.css';
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { format } from 'sql-formatter';
import { parsers } from './plugins/SqlLogParser';

function App() {
  const [sqlInput, setSqlInput] = useState('');
  const [formattedSql, setFormattedSql] = useState('');
  const [activeTooltip, setActiveTooltip] = useState(null);

  const parseSqlFromLog = (content) => {
    // 遍历所有解析器尝试解析
    for (const parser of parsers) {
      if (parser.canParse(content)) {
        const result = parser.parse(content);
        if (result) return result;
      }
    }
    // 如果没有匹配的解析器，返回原始内容
    return { sql: content, params: [] };
  };

  const handleFormat = () => {
    try {
      const parsedSql = parseSqlFromLog(sqlInput);
      const formatted = format(parsedSql.sql, {
        language: 'sql',
        uppercase: true,
        params: parsedSql.params,
      });
      setFormattedSql(formatted);
    } catch (error) {
      console.error(error);
      alert('SQL 格式化出错，请检查您的 SQL 语句');
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formattedSql).then(() => {
      // 可以添加一些复制成功的视觉反馈，比如临时改变按钮文字
      const copyBtn = document.querySelector('.copy-button');
      const originalText = copyBtn.textContent;
      copyBtn.textContent = '已复制！';
      setTimeout(() => {
        copyBtn.textContent = originalText;
      }, 1500);
    });
  };

  const handleFormatBadgeClick = (parserName) => {
    setActiveTooltip(activeTooltip === parserName ? null : parserName);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>SQL 格式化工具</h1>
        <div className="supported-formats">
          {parsers.map(parser => (
            <div
              key={parser.name}
              className={`format-badge ${activeTooltip === parser.name ? 'active' : ''}`}
              onClick={() => handleFormatBadgeClick(parser.name)}
              title={parser.description}
            >
              {parser.name}
              {activeTooltip === parser.name && (
                <div className="format-tooltip">
                  {parser.description}
                </div>
              )}
            </div>
          ))}
        </div>
      </header>
      <main className="App-main">
        <div className="editor-container">
          <h2>输入 SQL</h2>
          <CodeMirror
            value={sqlInput}
            height="200px"
            extensions={[sql()]}
            onChange={(value) => setSqlInput(value)}
            theme="dark"
            basicSetup={{
              lineNumbers: true,
              foldGutter: false, // 减少移动端不必要的功能
              dropCursor: false,
              allowMultipleSelections: false,
              indentOnInput: false
            }}
          />
          <div className="mobile-buttons">
            <button className="clear-button" onClick={() => setSqlInput('')}>
              清空
            </button>
            <button className="format-button" onClick={handleFormat}>
              格式化
            </button>
          </div>
        </div>

        <div className="format-button-container">
          <button className="format-button" onClick={handleFormat} title="格式化">
            <svg viewBox="0 0 24 24" width="24" height="24">
              <path fill="currentColor" d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
            </svg>
          </button>
        </div>

        <div className="editor-container">
          <div className="result-header">
            <h2>格式化结果</h2>
            <button className="copy-button" onClick={handleCopy}>
              一键复制
            </button>
          </div>
          <CodeMirror
            value={formattedSql}
            height="200px"
            extensions={[sql()]}
            editable={false}
            theme="dark"
          />
        </div>
      </main>
      <footer className="App-footer">
        <div className="footer-links">
          <h3>友情链接</h3>
          <a href="https://www.hezi.fun" target="_blank" rel="noopener noreferrer">
            导航盒子
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
