// 基础解析器接口
class BaseSqlLogParser {
    name = '';
    description = '';

    canParse(content) {
        return false;
    }

    parse(content) {
        return { sql: content, params: [] };
    }
}

// SQLAlchemy日志解析器
class SqlalchemyLogParser extends BaseSqlLogParser {
    name = 'SQLAlchemy';
    description = '支持SQLAlchemy日志格式，例如：INFO sqlalchemy.engine.Engine SELECT * FROM table WHERE id = %s';

    canParse(content) {
        return content.includes('sqlalchemy.engine.Engine');
    }

    parse(content) {
        const sqlMatch = content.match(/Engine (.+?)(?=\d{4}-|\[generated|\[cached|$)/s);
        const paramsMatch = content.match(/\[(generated|cached)[^\]]*\] \((.*?)\)/);

        if (!sqlMatch) return null;

        let params = [];
        let sql = sqlMatch[1].trim();

        if (paramsMatch) {
            sql = sql.replace(/%s/g, '?');
            params = paramsMatch[2].split(', ').map(param => {
                param = param.trim();
                // 如果参数是数字，直接返回
                if (!isNaN(param)) return param;
                // 如果参数是字符串，保留引号
                if (param.startsWith("'") || param.startsWith('"')) return param;
                // // 其他情况（如 JSON 路径表达式），加上引号
                return `'${param}'`;
            });
        }

        return { sql, params };
    }
}

// Django日志解析器
class DjangoLogParser extends BaseSqlLogParser {
    name = 'Django';
    description = '支持Django数据库日志格式，例如：(0.000) SELECT * FROM table WHERE id = 1; args=()';

    canParse(content) {
        return content.includes('(0.') && content.includes('; args=');
    }

    parse(content) {
        const sqlMatch = content.match(/\(\d+\.\d+\) (.*?); args=/);
        const argsMatch = content.match(/args=\((.*?)\)/);

        if (!sqlMatch) return null;

        let sql = sqlMatch[1].trim();
        let params = [];
        if (argsMatch) {
            sql = sql.replace(/%s/g, '?');
            params = argsMatch[1].split(', ').map(param => {
                param = param.trim();
                // 如果参数是数字，直接返回
                if (!isNaN(param)) return param;
                // 如果参数是字符串，保留引号
                if (param.startsWith("'") || param.startsWith('"')) return param;
                // // 其他情况（如 JSON 路径表达式），加上引号
                return `'${param}'`;
            });
        }

        return { sql, params };
    }
}

// MyBatis日志解析器
class MybatisLogParser extends BaseSqlLogParser {
    name = 'MyBatis';
    description = '支持MyBatis日志格式，例如：==>  Preparing: select * from users where id = ?';

    canParse(content) {
        return content.includes('==>  Preparing:') || content.includes('==> Parameters:');
    }

    parse(content) {
        const sqlMatch = content.match(/Preparing: (.*?)(?=\n|$)/);
        const paramsMatch = content.match(/Parameters: (.*?)(?=\n|$)/);

        if (!sqlMatch) return null;

        let sql = sqlMatch[1].trim();
        let params = [];

        if (paramsMatch) {
            params = paramsMatch[1].split(', ').map(param => {
                param = param.trim();
                // 处理带类型的参数，如: 1(Integer)
                const valueMatch = param.match(/(.*?)\((.*?)\)/);
                if (valueMatch) {
                    param = valueMatch[1].trim();
                }
                if (!isNaN(param)) return param;
                if (param.startsWith("'") || param.startsWith('"')) return param;
                return `'${param}'`;
            });
        }

        return { sql, params };
    }
}

// Hibernate日志解析器
class HibernateLogParser extends BaseSqlLogParser {
    name = 'Hibernate';
    description = '支持Hibernate日志格式，例如：Hibernate: select * from users where id=?';

    canParse(content) {
        return content.includes('Hibernate:') || content.includes('binding parameter');
    }

    parse(content) {
        const sqlMatch = content.match(/Hibernate: (.*?)(?=\n|$)/);
        const paramsMatch = Array.from(content.matchAll(/binding parameter \[\d+\] as \[(.*?)\] \[(.*?)\]/g));

        if (!sqlMatch) return null;

        let sql = sqlMatch[1].trim();
        let params = [];

        if (paramsMatch.length > 0) {
            params = paramsMatch.map(match => {
                const value = match[1].trim();
                if (!isNaN(value)) return value;
                if (value.startsWith("'") || value.startsWith('"')) return value;
                return `'${value}'`;
            });
        }

        return { sql, params };
    }
}

// Sequelize日志解析器
class SequelizeLogParser extends BaseSqlLogParser {
    name = 'Sequelize';
    description = '支持Sequelize日志格式，例如：Executing (default): SELECT * FROM users WHERE id = $1';

    canParse(content) {
        return content.includes('Executing (default):');
    }

    parse(content) {
        const sqlMatch = content.match(/Executing \(default\): (.*?)(?=\n|$)/);
        if (!sqlMatch) return null;

        let sql = sqlMatch[1].trim();
        // Sequelize通常使用$1, $2这样的参数占位符，将其转换为?
        sql = sql.replace(/\$\d+/g, '?');

        // Sequelize的参数通常直接内联在SQL中，所以这里不需要额外处理参数
        return { sql, params: [] };
    }
}

// 解析器注册表
export const parsers = [
    new SqlalchemyLogParser(),
    new DjangoLogParser(),
    new MybatisLogParser(),
    new HibernateLogParser(),
    new SequelizeLogParser(),
]; 